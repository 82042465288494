import Amplify from "aws-amplify";
import awsExports from "../../aws-exports";
import { useRouter } from 'next/router';

import Registration from '/components/registration/registration';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import CookiesBanner from "../../components/legals/cookiesBanner";
import useNextRouterHook from "../../hooks/useNextRouterHook";
import NextHead from "../../components/header/nextHead";

Amplify.configure(awsExports);

function SignIn() {
    const router = useRouter();
    const routerTo = useNextRouterHook();
    return (
        <>
            <NextHead>
                <meta name="robots" content="noindex" />
            </NextHead>
            <Header routerTo={routerTo} />
            <Registration routerTo={routerTo} location={router.pathname} />
            <CookiesBanner />
            <Footer />
        </>
    )
}

export default SignIn;
